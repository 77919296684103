import React from "react";
import theme from "theme";
import { Theme, Box, Icon, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdArrowDownward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"test"} />
		<Helmet>
			<title>
				Quarkly export
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
		</Helmet>
		<Components.StrapiFieldBasic />
		<Components.StrapiProvider type="single" collection="about-page" quarkly-title="AboutPageExample">
			<Components.Seo
				seoTitle="This is it"
				seoDescription="Description"
				type="single"
				componentName="SEO"
				collection="about-page"
				SeoTitle="Default SEO Title"
				SeoDescription="Default SEO Description"
				ogTitle="Default OG Title"
				ogDescription="Default OG Description"
			/>
			<Section
				background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://images.unsplash.com/photo-1510125594188-5afc74c8cc43?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80) center/cover"
				padding="60px 0"
				sm-padding="40px 0"
				color="--light"
				font="--base"
			>
				<Box margin="-16px -16px -16px -16px" display="flex">
					<Box display="flex" padding="16px 16px 16px 16px" width="75%" md-width="100%">
						<Box display="flex" flex-direction="column">
							<Components.StrapiField
								fieldType="text"
								fields="Subtitle,Title"
								isDynamicZone
								dynamicZoneComponentId="1"
								componentName="Hero"
							>
								<Override
									slot="Text-0"
									text-transform="uppercase"
									letter-spacing="1px"
									color="--lightD2"
									margin="0"
								/>
								<Override
									slot="Text-1"
									as="h1"
									font="--headline1"
									md-font="--headline2"
									margin="10px 0"
								/>
							</Components.StrapiField>
						</Box>
					</Box>
				</Box>
				<Box text-align="center" margin="96px 0 0 0">
					<Components.StrapiField fieldType="text" fields="Title" componentName="FirstContent" />
					<Icon category="md" margin="0 auto" icon={MdArrowDownward} />
				</Box>
			</Section>
		</Components.StrapiProvider>
		<Components.StrapiProvider collection="my-collection" mediaFolderUrl="https://strapi-4qbqe-u18919.vm.elestio.app" type="collection">
			<Section>
				<Box min-width="100px" min-height="100px">
					<Components.StrapiField
						id="6"
						collection="my-collection"
						fields="myMediaSingle"
						fieldType="image"
						text-align="center"
						size="small"
					/>
					<Components.StrapiField
						id="1"
						collection="my-collection"
						fields="mytextfield,mynumberfield"
						fieldType="text"
						text-align="center"
						justify-content="flex-end"
					>
						<Override slot="Text-1" font="--lead" />
					</Components.StrapiField>
					<Components.StrapiField
						id="2"
						collection="my-collection"
						fields="myRichTextJson"
						text-align="center"
						justify-content="flex-end"
						fieldType="richText"
					>
						<Override slot="RichTextWrapper-0">
							<Override slot="heading-1" color="--secondary" />
							<Override slot="list-10" text-align="left" />
							<Override slot="list-11" text-align="left" />
							<Override slot="paragraph-13" text-align="left" />
							<Override slot="image-14" width="300px" height="300px" margin="0px auto 0px auto" />
							<Override slot="paragraph-15" text-align="left" />
						</Override>
					</Components.StrapiField>
					<Components.StrapiField id="5" fields="myDateTime" fieldType="date" locale="en-GB" />
					<Components.StrapiField id="5" fields="myDateTime" fieldType="dateTime" locale="hu-HU" />
				</Box>
			</Section>
		</Components.StrapiProvider>
		<Components.StripeApp />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"665a226e1d712a0023345fdf"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});